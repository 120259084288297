import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {HttpShowError} from 'projects/hcl-lib/src/lib/decorator/http/http-show-error.decorator'
import {InvocationResult} from 'projects/hcl-lib/src/lib/interfaces/invocation-result'
import {Observable} from 'rxjs'
import {map} from 'rxjs/operators'
import {HclConfigService} from '../../config/hcl.config.service'
import {Customer, CustomerNames} from 'projects/hcl-lib/src/lib/interfaces/customer'
import { CONTENT_TYPE } from '../../constants/content-type'

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(
    private httpClient: HttpClient,
    private configService: HclConfigService
  ) {
    // do nothing
  }

  @HttpShowError()
  getChildren(ids: string[], page: Number, perPage: Number, search?: string): Observable<Customer[]> {
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())
    ids.forEach(id => {
      queryParams = queryParams.append('customerId', id);
    })
    if (search) {
      queryParams = queryParams.set('search', search)
    }
    return this.httpClient.get(this.configService.cmnUserApiBaseUrl + '/customers/children', { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getAvailableCustomers(page: Number, perPage: Number, search?: string): Observable<CustomerNames[]> {
    let headers: HttpHeaders = new HttpHeaders().append(
      'Accept',
      CONTENT_TYPE.AUTOCOMPLETE_RESULTS
    )
    let queryParams = new HttpParams()
      .set('perPage', perPage.toString())
      .set('page', page.toString())

    if (search) {
      queryParams = queryParams.set('search', search)
    }
    return this.httpClient.get(`${this.configService.cmnUserApiBaseUrl}/customers/children`, { params: queryParams, headers }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getCustomer(id: string, cid?: string): Observable<Customer> {
    let queryParams = new HttpParams()
    if (cid !== undefined) {
      queryParams = queryParams.set("cid", cid)
    }
    return this.httpClient.get(this.configService.cmnUserApiBaseUrl + '/customers/' + id, { params: queryParams }).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError()
  getAncestorCustomerIds(customerId: string): Observable<string[]> {
    return this.httpClient.get(this.configService.cmnUserApiBaseUrl + '/customers/' + customerId + '/ancestors').pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }

  @HttpShowError(true)
  changeParentCustomer(customerId: string, newParentCustomerId: string): Observable<Customer> {
    const url = `${this.configService.cmnUserApiBaseUrl}/customers/${customerId}/update-parent`
    const changeParentCustomerDto = {
      parentCustId: newParentCustomerId
    }
    return this.httpClient.post(url, changeParentCustomerDto).pipe(
      map((res: any) => {
        return (res as InvocationResult).data
      })
    )
  }
}
