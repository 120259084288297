import { Injectable, Optional } from '@angular/core';
import { HclConfig } from './hcl.config';

@Injectable({
  providedIn: 'root',
})
export class HclConfigService {

  readonly cmnAppApiBaseUrl?: string
  readonly cmnUserApiBaseUrl?: string
  readonly mediaApiBaseUrl?: string
  readonly institutionApiBaseUrl?: string
  stripePublishableKey?: string

  constructor(@Optional() config?: HclConfig) {
    if (config) {
      this.cmnAppApiBaseUrl = config.cmnAppApiBaseUrl
      this.cmnUserApiBaseUrl = config.cmnUserApiBaseUrl
      this.mediaApiBaseUrl = config.mediaApiBaseUrl
      this.institutionApiBaseUrl = config.institutionApiBaseUrl
      this.stripePublishableKey = config.stripePublishableKey
    }
  }

}
