import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { InvocationResultFailure } from 'projects/hcl-lib/src/lib/interfaces/invocation-result';
import { SnackBarComponent } from '../../widgets/snack-bar/snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) {
  }

  private showSnackBarError(errorMessage: string, userAction?: string, duration: number = 5000) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      data: {
        panelClass: 'error-snackbar',
        message: errorMessage,
        userAction: userAction
      }
    });
  }

  snackBarFromInvocationResultFailure(
    failure: InvocationResultFailure,
    userAction: string | undefined = undefined,
    duration: number = 5000
  ) {
    let translatedMessage = ""
    if (failure.errorCode) {
      translatedMessage = this.translateService.instant("ERROR") + ": " + failure.errorCode + "\n"
    }
    if (failure.translationKey) {
      translatedMessage = translatedMessage + this.translateService.instant(failure.translationKey)
    } else {
      translatedMessage = translatedMessage + this.translateService.instant("HTTP_SHOW_ERROR.UNKNOWN_ERROR")
    }
    this.showSnackBarError(translatedMessage, userAction, duration)
  }

  snackBarError(
    translationKey: string | undefined = undefined,
    userAction: string | undefined = undefined,
    duration: number = 5000,
    nonTranslatedMessage: string | undefined = undefined
  ) {
    if (translationKey && nonTranslatedMessage) {
      this.showSnackBarError(this.translateService.instant(translationKey) + " " + nonTranslatedMessage, userAction, duration)
    } else if (translationKey) {
      this.showSnackBarError(this.translateService.instant(translationKey), userAction, duration)
    } else if (nonTranslatedMessage) {
      this.showSnackBarError(nonTranslatedMessage, userAction, duration)
    }
  }

  snackBarSuccess(translationKey: string, duration: number = 5000) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      duration,
      data: {
        panelClass: 'success-snackbar',
        message: this.translateService.instant(translationKey)
      }
    });
  }
}
