import { HttpErrorResponse } from '@angular/common/http';
import { Type } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { HumecloudCommonDecoratorModule } from '../../humecloud-common-decorator.module';
import { SnackBarService } from '../../services/snackbar/snackbar.service';

export function HttpShowError(requireUserInteraction = false): MethodDecorator {

  return function (target: any, propertyKey: string | symbol, descriptor: PropertyDescriptor) {
    const original = descriptor.value;

    descriptor.value = function () {

      const snackBarService = HumecloudCommonDecoratorModule.injector?.get<SnackBarService>(SnackBarService as Type<SnackBarService>)
      let userInteraction: string | undefined = undefined
      let durationTime = 5000
      if (requireUserInteraction) {
        userInteraction = "OK"
        durationTime = 0
      }

      return original.apply(this, arguments)
        .pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (err.error && err.error.failure) {
                snackBarService?.snackBarFromInvocationResultFailure(err.error.failure, userInteraction, durationTime)
                console.error(err.error.failure.message)
              } else {
                snackBarService?.snackBarError("HTTP_SHOW_ERROR.UNKNOWN_ERROR", userInteraction, durationTime, err.message)
                console.error(err.message)
              }
            } else {
              snackBarService?.snackBarError("HTTP_SHOW_ERROR.UNKNOWN_ERROR", userInteraction, durationTime, err.stack)
            }
            throw err;
          })
        );
    };
    return descriptor;
  };

}
