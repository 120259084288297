import { Injector, NgModule } from '@angular/core';

@NgModule({
})
export class HumecloudCommonDecoratorModule {
  static injector?: Injector = undefined

  constructor(injector: Injector) {
    HumecloudCommonDecoratorModule.injector = injector
  }
}
